import React from "react";
import { Link } from "gatsby";

// Styles
import "./BookDiscussion.css";
import "../styles/buttons.css";

// Images
import BookingBG from "../assets/images/booking-bg.png";

const NavLink = (props: any) => <Link activeClassName="active" {...props} />;

interface BookDiscussionProps {
  title?: string;
  link?: string;
  button?: string;
}

const BookDiscussion: React.FC<BookDiscussionProps> = ({
  title,
  link,
  button,
}) => {
  return (
    <div className="booking-card">
      <img src={BookingBG} alt={"Skodel"} />
      <h3>{title}</h3>
      <div className="booking-button">
        <NavLink to={link} className="btn btn-white">
          {button}
        </NavLink>
      </div>
    </div>
  );
};

export default BookDiscussion;
