import React from "react";

// Styles
import "./StackedImage.css";

interface StackedImageProps {
  image?: string;
  title?: string;
  description?: string;
  author?: string;
  link?: string;
}

const StackedImage: React.FC<StackedImageProps> = ({
  image,
  title,
  description,
  author,
  link,
}) => {
  return (
    <div className="stacked-image-wrapper">
      <div className="stacked-image-image">
        <img src={image} alt={"Skodel"} />
      </div>
      <div className="stacked-image-description">
        <h3>{title}</h3>
        <p>{description}</p>
        {author && (
          <p>
            <strong>{author}</strong>
          </p>
        )}
        {link && (
          <a target="_blank" href={link} className="stacked-image-link">
            <p>
              <strong>Read more</strong>
            </p>
          </a>
        )}
      </div>
    </div>
  );
};

export default StackedImage;
