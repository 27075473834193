import React, { useState } from "react";

// Styles
import "./FAQ.css";

// Images and icons
import ChevronDown from "../assets/icons/chevron-down.svg";

interface FAQProps {
  question?: string;
  answer?: string | React.ReactNode;
}

const FAQ: React.FC<FAQProps> = ({ question, answer }) => {
  const [isFAQExpand, setIsFAQExpand] = useState<boolean>(false);

  return (
    <div className={`${"faq-item"} ${isFAQExpand ? "expand" : ""}`}>
      <div className="faq-head" onClick={() => setIsFAQExpand(!isFAQExpand)}>
        <h3>{question}</h3>
        <img src={ChevronDown} alt={"Skodel"} />
      </div>
      <div className="faq-body">
        <p>{answer}</p>
      </div>
    </div>
  );
};

export default FAQ;
