import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

// Styles
import "./pricing.css";

// Components
import IndexLayout from "../layouts";
import Page from "../components/Page";
import StackedImage from "../components/StackedImage";
import FAQ from "../components/FAQ";
import BookDiscussion from "../components/BookDiscussion";

// Images and icons
import CurveBG from "../assets/images/curve-bg.svg";
import PricingIconDark from "../assets/icons/pricing-icon-dark.svg";
import PricingIconWhite from "../assets/icons/pricing-icon-white.svg";
import PricingCardBG from "../assets/images/leading-card-bg.png";
import TrustedLeader from "../assets/images/pricing-trusted-leader.jpg";

// @ts-ignore
const NavLink = (props: any) => <Link activeClassName="active" {...props} />;

const PricingStandardData = [
  {
    id: "PS0001",
    title: "Small",
    quantity: "1-49",
    price: "$2,000 - $5,000",
    over: "per annum",
    link: "/request-a-demo",
    button: "Book a Demo",
  },
  {
    id: "PS0002",
    title: "Medium",
    quantity: "50-99",
    price: "$5,000 - $10,000",
    over: "per annum",
    link: "/request-a-demo",
    button: "Book a Demo",
  },
  {
    id: "PS0003",
    title: "Large",
    quantity: "100-499",
    price: "$10,000 - $20,000",
    over: "per annum",
    link: "/request-a-demo",
    button: "Book a Demo",
  },
  {
    id: "PS0004",
    title: "Enterprise",
    quantity: "500+",
    price: "",
    over: "",
    link: "/request-a-demo",
    button: "Let's Chat",
  },
];

const FAQData = [
  {
    id: "FAQ0001",
    question: "What does a subscription get me?",
    answer:
      "You receive a psychosocial risk assessment aligned to your legislative requirements, training on how to use it and microsurveys that have been designed alongside regulators.",
  },
  {
    id: "FAQ0002",
    question: "What support does Skodel provide?",
    answer:
      "We offer onboarding and ongoing technical support for clients using the Skodel system as well support in reviewing psychosocial data, scoring risks and delivering training. ",
  },
  {
    id: "FAQ0003",
    question: "How long are the subscription terms?",
    answer:
      "Skodel offers monthly and yearly subscription terms. Multi-year deals can be discussed separately.",
  },
];

const META_TAGS = {
  title: "Affordable psychosocial hazard consultation",
  description:
    "Skodel is an affordable way to manage essential psychosocial hazard consultations.",
};

const Pricing = () => {
  const [pricingStandard, setPricingStandard] = useState<
    {
      id: string;
      title: string;
      quantity: string;
      price: string;
      over: string;
      link: string;
      button: string;
    }[]
  >([]);

  const [faq, setFAQ] = useState<
    { id: string; question: string; answer: string }[]
  >([]);

  useEffect(() => {
    setPricingStandard(PricingStandardData);
    setFAQ(FAQData);
  }, [setPricingStandard, setFAQ]);

  return (
    <IndexLayout withBlackLogo={true} workplacesHeader={false}>
      <Helmet>
        {/*  Primary Meta Tags */}
        <title>{META_TAGS.title}</title>
        <meta name="title" content={META_TAGS.title} />
        <meta name="description" content={META_TAGS.description} />

        {/*  Open Graph / Facebook  */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://skodel.com/education" />
        <meta property="og:title" content={META_TAGS.title} />
        <meta property="og:description" content={META_TAGS.description} />

        {/*  Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://skodel.com/education" />
        <meta property="twitter:title" content={META_TAGS.title} />
        <meta property="twitter:description" content={META_TAGS.description} />
      </Helmet>
      <Page>
        <section className="section-pricing">
          <div
            className="section-bg"
            style={{ backgroundImage: `url('${CurveBG}')` }}
          ></div>
          <div className="main-container">
            <div className="pricing-hero">
              <h1>Pricing</h1>
              <p>
                Skodel offers a tiered pricing model. Subscriptions include an
                initial strategy session and access to all Skodel tools and
                features.
              </p>
            </div>
            <div className="pricing-list">
              <div className="pricing-tab">
                <div className="pricing-list-wrapper">
                  <div className={`${"pricing-list-panel active"}`}>
                    <div className="pricing-list-grid">
                      {pricingStandard?.map((item, index) => (
                        <div
                          className="pricing-list-card"
                          key={item?.id + "-" + index}
                        >
                          <img src={PricingCardBG} alt={"Skodel"} />
                          <div className="pricing-list-detail">
                            <h4>{item?.title}</h4>
                            <div className="pricing-list-icon">
                              <img src={PricingIconWhite} alt={"Skodel"} />
                              <img src={PricingIconDark} alt={"Skodel"} />
                            </div>
                            <h2>
                              {item?.quantity} <span>users</span>
                            </h2>
                          </div>
                          {item?.price.length === 0 ? (
                            <h3>&nbsp;</h3>
                          ) : (
                            <h3>{item?.price}</h3>
                          )}
                          {item?.over.length === 0 ? (
                            <p>&nbsp;</p>
                          ) : (
                            <p>{item?.over}</p>
                          )}
                          <NavLink
                            className="btn btn-white btn-full btn-pricing"
                            to={item?.link}
                          >
                            {item?.button}
                          </NavLink>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-content-wrapper">
              <div className="stacked-content">
                <StackedImage
                  image={TrustedLeader}
                  title={"Protection, without compromise"}
                  description={
                    "Meeting compliance isn’t easy. You have to gather worker feedback, organise it into a risk assessment, score the risks, document all of this and then redo it each year. This is tough and usually comes at the expense of other priorities. It impacts productivity in the short term. Skodel resolves this tradeoff issue through its speed and simplicity. It allows us to meet compliance and safeguard our teams and organisation without interrupting other essential priorities."
                  }
                />
              </div>
              <div className="pricing-faq-wrapper">
                <div className="section-title text-center">
                  <h2>Frequently asked questions</h2>
                </div>
                <div className="pricing-faq-content">
                  <div className="pricing-faq-list">
                    {faq?.map((item, index) => (
                      <FAQ
                        key={item?.id + "-" + index}
                        question={item?.question}
                        answer={item?.answer}
                      />
                    ))}
                  </div>
                  <BookDiscussion
                    title={"Book in a discussion"}
                    link={"/request-a-demo"}
                    button={"Request A Demo"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Page>
    </IndexLayout>
  );
};

export default Pricing;
